'use client'

import { NavHashLink } from "react-router-hash-link";
import menu from "./../styles/menu.module.css";
import cx from "classnames";

const open_entradas = () => { 
    document.querySelector('.entradas').classList.toggle('active');
    close_platillos();
    close_bebidas();
}

const open_platillos = () => { 
    document.querySelector('.platillos').classList.toggle('active');
    close_entradas();
    close_bebidas();
}

const open_bebidas = () => { 
    document.querySelector('.bebidas').classList.toggle('active');
    close_entradas();
    close_platillos();
}

const close_all = () => {
    close_entradas();
    close_platillos();
    close_bebidas();
}

const close_entradas = () => { document.querySelector('.entradas').classList.remove('active'); }
const close_platillos = () => { document.querySelector('.platillos').classList.remove('active'); }
const close_bebidas = () => { document.querySelector('.bebidas').classList.remove('active'); }

export function MenuFloat() {
    return(
        <div className={ cx(menu.floating_menu_con, 'floating_menu_flag') }>
            <div className={ menu.floating_sub }>
                <div className={ cx(menu.floating_submenu, menu.entradas, 'entradas') }>
                    <NavHashLink onClick={ close_entradas } to="#entradas-frias" className={ menu.floating_sub_el }>Entradas frías</NavHashLink>
                    <NavHashLink onClick={ close_entradas } to="#entradas-calientes" className={ menu.floating_sub_el }>Entradas calientes</NavHashLink>
                </div>
                <div className={ cx(menu.floating_submenu, menu.platillos, 'platillos') }>
                    <NavHashLink onClick={ close_platillos } to="#platos-fuertes" className={ menu.floating_sub_el }>Platos fuertes</NavHashLink>
                    <NavHashLink onClick={ close_platillos } to="#parrilla" className={ menu.floating_sub_el }>De la parrilla</NavHashLink>
                    <NavHashLink onClick={ close_platillos } to="#guarniciones" className={ menu.floating_sub_el }>Guarniciones</NavHashLink>
                    <NavHashLink onClick={ close_platillos } to="#sushi" className={ menu.floating_sub_el }>Rollos / Makis</NavHashLink>
                    {/*<NavHashLink onClick={ close_platillos } to="#nigiris" className={ menu.floating_sub_el }>Nigiris</NavHashLink>*/}
                </div>
                <div className={ cx(menu.floating_submenu, menu.bebidas, 'bebidas') }>
                    <NavHashLink onClick={ close_bebidas } to="#digestivos" className={ menu.floating_sub_el }>Digestivos</NavHashLink>
                    {/*<NavHashLink onClick={ close_bebidas } to="#vinos" className={ menu.floating_sub_el }>Vinos de mesa</NavHashLink>*/}
                    <NavHashLink onClick={ close_bebidas } to="#destilados" className={ menu.floating_sub_el }>Destilados</NavHashLink>
                    <NavHashLink onClick={ close_bebidas } to="#licores" className={ menu.floating_sub_el }>Licores</NavHashLink>
                    <NavHashLink onClick={ close_bebidas } to="#mixologia" className={ menu.floating_sub_el }>Mixología</NavHashLink>
                </div>
            </div>
            <div className={ menu.floating_menu }>
                <div onClick={ open_entradas } className={ menu.floating_menu__el }>Entradas</div>
                <div onClick={ open_platillos } className={ menu.floating_menu__el }>Platillos</div>
                <NavHashLink onClick={ close_all } className={ menu.floating_menu__el } to="#postres">Postres</NavHashLink>
                <div onClick={ open_bebidas } className={ menu.floating_menu__el }>Bebidas</div>
            </div>
        </div>
    )
}