import { Navbar } from "../components/Navigation"
import { ResponsiveImage } from "../components/Images"
import { Footer, Copyright } from "../components/Footer"
import { MenuFloat } from "../components/MenuFloat"
import menu from "./../styles/menu.module.css"
import cx from "classnames"

import { MenuSection, BebidasSection } from "../components/MenuElements"

import { entradas_frias, entradas_calientes, platos_fuertes, parrilla, guarniciones, postres, digestivos, rollos, nigiris, vinos, destilados, licores, mixologia, mixologia1 } from "../array/menu"

import { useEffect } from "react"

export default function Menu() {

    useEffect(() => {
        const handleScroll = () => {
            window.scrollY > 100 ? document.querySelector('.floating_menu_flag').classList.add('show') : document.querySelector('.floating_menu_flag').classList.remove('show');
        };
        window.addEventListener('scroll', handleScroll);
        return () => { window.removeEventListener('scroll', handleScroll); };
    }, []);

    return (
        <>
            <Navbar/>

            {/* Header */}
            <ResponsiveImage classes={ menu.header } route="menu/hero" alt="Platillo carne Agatha" />

            {/* Menu */}
            <div className={ cx(menu.menu_con, 'section') }>
                {/* Entradas frías */}
                <MenuSection id_section="entradas-frias" title="ENTRADAS FRÍAS" elements={ entradas_frias } ostiones={ false } imgSrc="entradas-frias"/>
                {/* Entradas calientes */}
                <MenuSection id_section="entradas-calientes" classes={ menu.reverse } title="ENTRADAS CALIENTES" elements={ entradas_calientes } imgSrc="entradas-calientes"/>
                {/* Platos fuertes */}
                <MenuSection id_section="platos-fuertes" title="PLATOS FUERTES" elements={ platos_fuertes } imgSrc="plato-fuerte" />
                {/* De la parrilla */}
                <MenuSection id_section="parrilla" title="DE LA PARRILLA" elements={ parrilla } disclaimer="Nuestros platos a la parrilla vienen con una guarnicion a escoger."/>
                {/* Guarniciones */}
                <MenuSection id_section="guarniciones" title="GUARNICIONES" elements={ guarniciones } disclaimer="En caso de tener alguna alergia o restriccion, porfavor hagalo saber a su mesero antes de ordenar. El consumo de carnes y mariscos crudos representa un riesgo a la salud, es responsabilidad del comensal su consumo. Todos los precios incluyen IVA. LOS GRAMAJES DE NUESTRAS PROTEINAS SON EN CRUDO."/>
                {/* Rollos */}
                <MenuSection id_section="sushi" handroll={ false } classes={ menu.reverse } title="ROLLOS / MAKIS" elements={ rollos } imgSrc="sushi" />
                {/* Nigiris 
                <MenuSection id_section="nigiri" title="NIGIRI" elements={ nigiris } />
                */}
                {/* Postres */}
                <MenuSection id_section="postres" title="POSTRES" elements={ postres } imgSrc="postres" />
                {/* Digestivos */}
                <MenuSection id_section="digestivos" title="DIGESTIVOS" elements={ digestivos } />
                {/* Vinos de mesa 
                <BebidasSection id_section="vinos" title="VINOS DE MESA" elements={ vinos } />
                */}
                {/* Destilados */}
                <BebidasSection id_section="destilados" title="DESTILADOS" elements={ destilados } />
                {/* Licores */}
                <BebidasSection id_section="licores" title="LICORES" elements={ licores } />
                {/* Mixología */}
                <MenuSection id_section="mixologia" title="MIXOLOGÍA" elements={ mixologia1 } />
                <BebidasSection id_section="mixologia1" title="" elements={ mixologia } />
            </div>

            <MenuFloat/>

            <Footer/>
            <Copyright/>
        </>
    )
}