import { Image } from "../components/Images"
import menu from "./../styles/menu.module.css"
import cx from "classnames"

export function MenuTitle({ title }) {
    return(
        <div className={ cx(menu.menu_title, 'title') }>{ title }</div>
    )
}

export function BebidaTitle({ title, botella, copa }) {
    return(
        <div className={ menu.bebida_title_con }>
            { 
                title != "no" 
                    ? <div className={ menu.bebida_title_nombre }>{ title }</div>
                    : <div className={ menu.bebida_title_nombre }></div>
            }
            { 
                botella != "no" 
                    ? <div className={ menu.bebida_title_botella }>Botella { botella != "" && <span>{ botella }</span> }</div>
                    : <div className={ menu.bebida_title_botella }></div>
            }
            { 
                copa != "no" 
                    ? <div className={ menu.bebida_title_copa }>Copa { copa != "" && <span>{ copa }</span> }</div>
                    : <div className={ menu.bebida_title_copa }></div>
            }
        </div>
    )
}

export function Platillo({ platillo }) {
    return(
        <div className={ menu.platillo }>
            <div className={ menu.platillo_title }>
                <div className={ menu.platillo_nombre }>
                    { platillo.nombre }
                    { platillo.gramos != "" && <span>{ platillo.gramos }</span> }
                </div>
                <div className={ menu.platillo_precio }>{ platillo.precio }</div>
            </div>
            { platillo.des != "" && <div className={ menu.platillo_des }>{ platillo.des }</div> }
        </div>
    )
}

export function Bebida({ elemento }) {
    return(
        <div className={ menu.bebida }>
            <div className={ menu.bebida_nombre }>{ elemento.nombre }<span>{ elemento.subtitulo }</span></div>
            <div className={ menu.bebida_botella }>{ elemento.botella }</div>
            <div className={ menu.bebida_copa }>{ elemento.copa }</div>
        </div>
    )
}

export function PlatilloOstiones() {
    return (
        <div className={ menu.platillo }>
            <div className={ menu.platillo_title }>
                <div className={ menu.platillo_nombre }>OSTIONES<span>(6 pzs)</span></div>
            </div>
            <div className={ menu.platillo_des_multiple }>
                <div className={ menu.platillo_des_multiple__el }>
                    <div className={ menu.platillo_des_multiple__el__nombre }>Frescas</div>
                    <div className={ menu.platillo_des_multiple__el__precio }>252</div>
                </div>
                <div className={ menu.platillo_des_multiple__el }>
                    <div className={ menu.platillo_des_multiple__el__nombre }>Rockefeller</div>
                    <div className={ menu.platillo_des_multiple__el__precio }>285</div>
                </div>
            </div>
        </div>
    )
}

export function PlatilloSushi() {
    return (
        <div className={ menu.platillo }>
            <div className={ menu.platillo_title }>
                <div className={ menu.platillo_nombre }>HAND ROLL</div>
            </div>
            <div className={ menu.platillo_des_multiple }>
                <div className={ menu.platillo_des_multiple__el }>
                    <div className={ menu.platillo_des_multiple__el__nombre }>TUNA SPICY</div>
                    <div className={ menu.platillo_des_multiple__el__precio }>115</div>
                </div>
                <div className={ menu.platillo_des_multiple__el }>
                    <div className={ menu.platillo_des_multiple__el__nombre }>SALMÓN SPICY</div>
                    <div className={ menu.platillo_des_multiple__el__precio }>140</div>
                </div>
                <div className={ menu.platillo_des_multiple__el }>
                    <div className={ menu.platillo_des_multiple__el__nombre }>ANGUILA</div>
                    <div className={ menu.platillo_des_multiple__el__precio }>165</div>
                </div>
            </div>
        </div>
    )
}

export function MenuSection({ id_section, title, elements, ostiones=false, handroll=false, imgSrc="", disclaimer="", classes="" }) {

    const left = elements.find(element => element.id == 'left');
    const right = elements.find(element => element.id == 'right');

    return (
        <div id={ id_section } className={ cx(menu.menu_content, 'content', classes) }>
            <MenuTitle title={ title } />
            <div className={ cx(menu.menu_section,'content_half') }>
                { left.platillos.map( (el, index) => <Platillo key={ index } platillo={ el }/> ) }
                { handroll == true && <PlatilloSushi/> }
            </div>
            <div className={ cx(menu.menu_section,'content_half') }>
                { right.platillos.map( (el, index) => <Platillo key={ index } platillo={ el }/> ) }
                { ostiones == true && <PlatilloOstiones/> }
                { imgSrc != "" && <Image classes={ menu.menu_image } route={ "/images/menu/" + imgSrc } alt="Section image"/>}
            </div>
            { disclaimer != "" && <div className={ menu.menu_disclaimer }>{ disclaimer }</div> }
        </div>
    )
}

export function BebidasSection({ id_section, title, elements }) {

    return(
        <div id={ id_section } className={ cx(menu.menu_content, 'content') }>
            { title != "" && <MenuTitle title={ title}/> }
            { 
                elements.map((element, index) => (
                    <div key={ index } className={ menu.bebidas_container }>
                        <BebidaTitle title={ element.nombre } botella={ element.botella } copa={ element.copa }/>
                        { element.elements.map((bebida, index) => <Bebida key={ index } elemento={ bebida }/>) }
                    </div>
                ) 
            )}
        </div>
    )
}