import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link"

export function Footer() {
    return(
        <div className="footer section">
            <div className="content">
                <div className="footer_el">
                    <img className="footer_el__logo" src="/images/navbar/logo.png" alt="Logo Agatha"/>
                    <a href="https://goo.gl/maps/AvEkP9WgzzUbtFSs5" target="_blank" className="footer_el__doble">
                        <img src="/images/footer/map.png" alt="Map icon"/>Av. Gaviotas 225, Las Gaviotas, 82110 Mazatlán,Sin.
                    </a>
                    <a href="tel:6699903202" target="_blank" className="footer_el__doble">
                        <img src="/images/footer/tel.png" alt="Map icon"/>(669) 990 3202
                    </a>
                </div>
                <div className="footer_el">
                    <div className="footer_el__title">Páginas</div>
                    <Link to="/" className="footer_el__link">Home</Link>
                    <HashLink to="/#concepto" className="footer_el__link">Concepto</HashLink>
                    <Link to="/menu" className="footer_el__link">Menú</Link>
                    <a href="tel:6699903202" className="footer_el__link">Reservar</a>
                </div>
                <div className="footer_el">
                    <div className="footer_el__title">Horarios</div>
                    <div className="footer_el__des">Domingo-Miércoles:<br/>12:00pm-12:00am</div>
                    <div className="footer_el__des">Jueves-Sábadp:<br/>12:00pm-01:00am</div>
                </div>
                <div className="footer_el">
                    <div className="footer_el_social">
                        <img src="/images/footer/social.png" alt="Redes sociales icon"/>
                        <a href="https://www.facebook.com/agathakitchenbar" target="_blank"></a>
                        <a href="https://wa.me/+5216691496111?text=%C2%A1Hola!%20Me%20gustar%C3%ADa%20reservar" target="_blank"></a>
                        <a href="https://www.instagram.com/agatha.kb/" target="_blank"></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function Copyright() {
    return(
        <div className="copyright section">
            <div className="content">© Agatha Kitchen Bar 2023</div>
        </div>
    )
}